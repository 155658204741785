import styled from 'styled-components';

export const ErrorPageElement = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0 50px 0;
  
  .error-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 580px;
    border-top: 20px solid var(--theme-dark-purple);
    border-radius: 8px;
    padding: 70px 40px 25px 40px;
    background-color: var(--white);
    box-shadow: rgb(0 0 0 / 25%) -5px -5px 70px;
    
    &__pre-title,
    &__text {
      margin: 0 0 40px 0;
      padding: 0 30px;
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      line-height: 39px;
      letter-spacing: 1.25px;
    }
    
    &__title {
      margin: 0 0 40px 0;
      text-align: center;
      font-size: 48px;
      font-weight: 800;
      line-height: 1;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      color: var(--theme-dark-purple);
    }
    
    &__text {
      &--small {
        margin-bottom: 20px;
        padding: 0;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
      }
    }
    
    &__links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      
      &__item {
        position: relative;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        text-decoration: none;
        letter-spacing: 1.2px;
        color: var(--theme-purple);
        
        &:hover {
          cursor: pointer;
          
          &:after {
            transform: scale(0);
          }
        }
        
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: var(--theme-purple);
          transform: scale(1);
          transition: .3s all ease-in-out;
        }
      }
    }
    
    &__btn {
      display: flex;
      align-items: center;
      width: 240px;
      height: 48px;
      margin-bottom: 30px;
      border: none;
      border-radius: 34px;
      background-color: var(--theme-purple);
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 1.25px;
      color: var(--white);
      
      &:hover {
        cursor: pointer;
      }
      
      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        margin-right: 10px;
        border-radius: 50%;
        background-color: var(--white);
        
        &__item {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 767px) {
    padding: 40px 0;
  
    .error-box {
      width: 100%;
      padding: 25px;
      
      &__title {
        font-size: 40px;
      }
      
      &__text {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
`;