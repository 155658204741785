import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const BreadcrumbsEndpoint = memo( ( { text } ) => {
  return (
    <>
      <span className="separator">></span>
      <span className="endpoint">{ text }</span>
    </>
  )
});

BreadcrumbsEndpoint.defaultProps = {
  text: ''
};

BreadcrumbsEndpoint.propTypes = {
  text: PropTypes.string
};

export default BreadcrumbsEndpoint;