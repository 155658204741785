import React              from 'react';
import { Link, navigate } from 'gatsby';

// import styles
import { ErrorPageElement } from '../styles/Blog404';

// import components
import PageMeta from '../components/elements/PageMeta';
import Seo      from '../components/common/Seo';

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" description="404: Not found" lang="de" />
    <PageMeta type="page" text="404" />
    <div className="container">
      <ErrorPageElement>
        <div className="error-box">
          <h2 className="error-box__pre-title">It’s just a</h2>
          <h1 className="error-box__title">404 Error!</h1>
          <p className="error-box__text">What you’re looking for may have been misplaced</p>
          <div className="error-box__links">
            <span className="error-box__links__item"
                  role="presentation"
                  onClick={ () => navigate( -1 ) }>Go back</span>
            <Link className="error-box__links__item" to="/">Go to Home</Link>
          </div>
        </div>
      </ErrorPageElement>
    </div>
  </>
);

export default NotFoundPage;
