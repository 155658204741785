import React, { memo } from 'react';
import PropTypes       from 'prop-types';

// import styles
import { PageMetaElement } from './styles';

// import components
import Breadcrumbs from '../Breadcrumbs';
import FollowUs    from '../FollowUs/index';

const PageMeta = memo( ( { type, text } ) => {
  return (
    <PageMetaElement>
      <div className="container">
        <div className="meta-wrapper">
          <Breadcrumbs type={ type } text={ text } />
          <FollowUs />
        </div>
      </div>
    </PageMetaElement>
  )
});

PageMeta.defaultProps = {
  type: 'home',
  text: ''
};

PageMeta.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string
};

export default PageMeta;