import React, { memo, useState, useContext, useEffect } from 'react';
import { graphql, useStaticQuery }                      from 'gatsby';

// import context
import { GlobalStateContext } from '../../../context/GlobalContextProvider';

// import styles
import { FollowUsElement } from './styles';

// import components
import SocialIcon from '../icons/SocialIcon';

const FollowUs = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          footer {
            firstInfobox {
              mobile_follow_text
            }
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [followText, setFollowText] = useState( '' );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { footer } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'de':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      setFollowText( footer[orderNumber].firstInfobox.mobile_follow_text );
    });
  }, [data, defaultLang] );

  return (
    <FollowUsElement>
      <span className="title">{ followText }</span>
      <SocialIcon className="item"
                  type="linkedin"
                  color="#0E1237"
                  altText="Follow us on LinkedIn"
                  link="https://www.linkedin.com/company/marketing-monkeys/" />
      <SocialIcon className="item"
                  type="twitter"
                  color="#0E1237"
                  altText="Follow us on Twitter"
                  link="https://twitter.com/MartechMonkeys" />
      <SocialIcon className="item"
                  type="facebook"
                  color="#0E1237"
                  altText="Follow us on Facebook"
                  link="https://www.facebook.com/MarketingMonkeysGmbH" />
      <SocialIcon className="item"
                  type="instagram"
                  color="#0E1237"
                  altText="Follow us on Instagram"
                  link="https://www.instagram.com/marketingmonkeys.ch/" />
    </FollowUsElement>
  )
});

export default FollowUs;