import styled from 'styled-components';

export const PageMetaElement = styled.section`
  .meta-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 40px 0;
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 1680px) {
    .meta-wrapper {
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 1440px) {
    .meta-wrapper {
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 1280px) {
    .meta-wrapper {
      margin-bottom: 5px;
    }
  }
  
  @media (max-width: 1024px) {
    .meta-wrapper {
      margin-bottom: 0;
    }
  }
  
  @media (max-width: 991px) {
    .meta-wrapper {
      margin: 5px 0 0 0;
    }
  }
  
  @media (max-width: 480px) {
    display: none;
  }
`;