import styled from 'styled-components';

export const FollowUsElement = styled.div`
  display: flex;
  align-items: flex-end;
  letter-spacing: 1.05px;
  
  .title {
    margin-right: 10px;
    line-height: 14px;
    font-weight: 700;
  }
  
  .item {
    margin: 0 5px;
    
    svg {
      width: 16px;
      height: 16px;
      
      path,
      ellipse {
        transition: .3s all ease-in-out;
      }
      
      &:hover {
        path,
        ellipse {
          fill: var(--theme-blue);
        }
      }
    }
  }
  
  @media (max-width: 991px) {
    display: none;
  }
`;