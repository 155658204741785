import styled from 'styled-components';

export const BreadcrumbsElement = styled.div`
  letter-spacing: 1.25px;
  
  .link {
    position: relative;
    color: var(--theme-deep-purple);
    text-decoration: none;
    transition: color .3s ease-in-out;
    
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -3px;
      display: block;
      width: 100%;
      border-bottom: 1px solid var(--theme-deep-purple);
      transform: scaleX(0);
      transition: transform .3s ease-in-out;
    }
    
    &:hover {
      color: var(--theme-blue);
      
      &::after {
        border-bottom-color: var(--theme-blue);
        transform: scaleX(1);
      }
    }
  }
  
  .separator {
    margin-left: 7px;
    margin-right: 7px;
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 991px) {
    font-size: 14px;
    line-height: 26px;
  }
`;