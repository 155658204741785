import React, { memo, useContext } from 'react';
import { Link }                    from 'gatsby';
import PropTypes                   from 'prop-types';

// import context
import { GlobalStateContext } from '../../context/GlobalContextProvider';

// import styles
import { BreadcrumbsElement } from '../../styles/elements/Breadcrumbs';

// import components
import BreadcrumbsEndpoint from './BreadcrumbsEndpoint';

const Breadcrumbs = memo( ( { type, text } ) => {
  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;
  const blogUrl = defaultLang === 'de' ? '/' : '/en/';

  return (
    <BreadcrumbsElement>
      <a className="link"
         href="https://www.marketingmonkeys.ch/"
         target="_blank"
         rel="noreferrer">Home</a>
      <span className="separator">></span>
      { type === 'home' ? <span className="endpoint">Blog</span> : <Link className="link" to={ blogUrl }>Blog</Link>}
      { type === 'single' ? <BreadcrumbsEndpoint text={ text } /> : false }
      { type === 'page' ? <BreadcrumbsEndpoint text={ text } /> : false }
      { type === 'category' ? <BreadcrumbsEndpoint text={ text } /> : false }
    </BreadcrumbsElement>
  )
});

Breadcrumbs.defaultProps = {
  type: 'home',
  text: ''
};

Breadcrumbs.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string
};

export default Breadcrumbs;